import React from 'react'
import Breadcrub from '../Common/Breadcrub'

const About = () => {
  return (
    <>
       <Breadcrub title="About Us" />
       <section class="aboutus-style-02">
            <div class="container">
                <div class="row align-items-center mt-n1-9">                    
                    <div class="col-lg-12 col-xl-12 mt-1-9">                       
                        <h2 class="mb-1-6 dark-color">Javitri College of Pharmacy</h2>
                        <p>Javitri College of Pharmacy is affiliated to "Board of Techinical Education, Lucknow, Uttar Pradesh" and approved by the Pharmacy Council of India (PCI), New Delhi.</p>
                        <p>Education at Javitri College of Pharmacy is focused on all round development of students besides academic excellence required to excel in the modern dynamic world.</p>
                       
                        <p>The college is dedicated to pharmacy education and is committed to produce competent pharmacist. We educate and train tomorrow’s pharmacists after extensive research. Pharmacists do more than simply dispense medication. They counsel patients, advice physicians and other healthcare professionals and research new treatments for diseases. In the ever-changing healthcare environment, pharmacists play a key role in the management of patients’ health.</p>
                        <p>The college is situated in esthetically designed, beautiful and lush green campus. There is a non-polluted and peaceful environment that prevails in the college and helpful in studies and develops the fresh mind of the students. The Institute has very good connectivity & the transport facility to the nearby areas.</p>
                    </div>
                </div>

                
                <div class="shape20">
                    <img src="img/bg/bg-02.jpg" alt="..." />
                </div>
                <div class="shape18">
                    <img src="img/bg/bg-01.jpg" alt="..." />
                </div>
                <div class="shape21">
                    <img src="img/bg/bg-03.jpg" alt="..." />
                </div>
            </div>
        </section>

        
    </>
  )
}

export default About
