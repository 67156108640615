import React from 'react'
import Breadcrub from '../Common/Breadcrub'
import { } from 'react-router-dom'

const Infrastructure = () => {
  return (
    <>
      <Breadcrub title="Infrastructure"  />
        <section class="aboutus-style-01">
            <div class="container">
                <div class="row align-items-center mb-5">
                     <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="img/content/class-room.jpg" alt="..." />
                            </div>                                                    
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">Class Rooms</h3>                                   
                                    <ul>
                                        <li>The class rooms are subject specific along with the overhead and LCD projector facility. The institution also has tutorial rooms for the students who require extra lectures.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>                    
                </div>

                <div class="row align-items-center mb-5">
                    
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">COMPUTER CENTER</h3>                                   
                                    <ul>
                                        <li>Computer centre has latest configuration desktop PCs on network controlled by server. Also the operating systems like Linux, Windows etc., various application software and database management system are available. The computer centre has the central power back up and will remain available to students at least 12-14 hours every day.</li>
                                        <li>This facility will cater to the need of all students & help them learn developing innovative program/software when they are young. The computer centre also has the highspeed internet connection for the students.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>   

                     <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="img/content/Computer.jpg" alt="..." />
                            </div>                                                    
                        </div>
                    </div>                 
                </div>

                <div class="row align-items-center mb-5">
                     <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="img/content/lab.jpg" alt="..." />
                            </div>                                                    
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">LABORATORIES</h3>     
                                    <p>The role of theoretical as well as the practical knowledge is mandatory in any technical education curriculum that makes the laboratories 'the backbone' of a technical institute. The institute is furnished with well advanced hi-tech labs having all modern facilities. Following are the operational labs:</p>                              
                                    <ul>
                                        <li>Pharmaceutical Chemistry Lab</li>
                                        <li>Pharmaceutics Lab</li>
                                        <li>Pharmacognosy Lab</li>
                                        <li>Anatomy Lab</li>
                                        <li>Machine Room</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>                    
                </div>

                <div class="row align-items-center mb-5">
                     
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">LIBRARY</h3>                                   
                                    <ul>
                                        <li>The learning and resource center (Library) is the hub of all intellectual and academics. The library has a large pool of resources viz. latest world class Marcel Dekker series of pharmaceutical sciences, Indian and international journals, Pharmacopoeias (USP, BP, IP, EP) and many more that makes it as diverse as pharma industry.</li>
                                        <li>Besides periodicals and references we keep the students up-to-date with the latest through the digital library that includes CDs, audio-visual, internet section, reprographic section.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>  

                    <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="img/content/LIBRARY.jpg" alt="..." />
                            </div>                                                    
                        </div>
                    </div>                  
                </div>

                <div class="row align-items-center mb-5">
                     <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="https://placehold.co/600x400/EEE/31343C" alt="..." />
                            </div>                                                    
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">CAFETERIA</h3>                                   
                                    <ul>
                                        <li>The institute has full-fledged cafeteria preparing and serving snacks, tea, coffee, cold drinks and various other foods.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>                    
                </div>

                <div class="row align-items-center mb-5">
                     
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">SPORTS</h3>                                   
                                    <ul>
                                        <li>At JIMSPD, sports and games are an integral and compulsory part of the education process. Sports help to build character and teach the importance of discipline in life. It inculcates a respect for rules and allows the participants to learn the value to self-control.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>   

                    <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="img/content/sports.jpg" alt="..." />
                            </div>                                                    
                        </div>
                    </div>                 
                </div>

                <div class="row align-items-center mb-5">
                     <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="https://placehold.co/600x400/EEE/31343C" alt="..." />
                            </div>                                                    
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">HOSTEL</h3>                                   
                                    <ul>
                                        <li>The institute provides hostel and mess, embedded with all modern facilities. Separate arrangements is available for girls and boys. 24 hours internet and Wi-Fi facilities is available in hostel. The security issue of all the students can't be compromised, so they remain under the supervision of wardens and the hostel is regularly guarded by the security staffs.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>                    
                </div>

                <div class="row align-items-center mb-5">
                     
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">TRANSPORTATION</h3>                                   
                                    <ul>
                                        <li>We have buses and other vehicles available for transportation. Our drivers are specially trained to deal with students softly so that any undue impact can be avoided on the student's minds. The transport facility is available from different points of Lucknow City.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>      

                    <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="img/content/transport.jpg" alt="..." />
                            </div>                                                    
                        </div>
                    </div>              
                </div>

                <div class="row align-items-center mb-5">
                     <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="https://placehold.co/600x400/EEE/31343C" alt="..." />
                            </div>                                                    
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">SECURITY</h3>                                   
                                    <ul>
                                        <li>It is a prime concern for us and the safety and security of our students come first. We have security guards round the clock to meet any eventual cause of concern for the security of our students.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>                    
                </div>

                <div class="row align-items-center mb-5">
                    
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h3 class="teacher-name mb-4">MEDICARE</h3>                                   
                                    <ul>
                                        <li>For meeting any emergency, we have round the clock facilities of doctor so that health of students can be looked after in the best possible manner. Health and welfare of students come first and it is our prime concern that your ward is well cures in the most scientific and holistic way. We have timely health check so that any untoward illness can be checked.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>   

                     <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="https://placehold.co/600x400/EEE/31343C" alt="..." />
                            </div>                                                    
                        </div>
                    </div>                 
                </div>
            </div>
        </section>
    </>
  )
}

export default Infrastructure
