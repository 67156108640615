import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Common/Header';
import Home from './Pages/Home';
import Footer from './Common/Footer';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Careers from './Pages/Careers';
import Inquiry from './Common/Inquiry';
import ChairmanMessage from './Pages/ChairmanMessage';
import PrincipalMessage from './Pages/PrincipalMessage';
import Courses from './Pages/Courses';
import PhotoGallery from './Pages/PhotoGallery';
import Downloads from './Pages/Downloads';
import Infrastructure from './Pages/Infrastructure';
import Admission from './Pages/Admission';
import ApprovalLetters from './Pages/ApprovalLetters';

function App() {
  return (
    <>
      <BrowserRouter>
          <Header />
          <Inquiry />
          <Routes>
              <Route path='/' element={<Home />}></Route>
              <Route path='/About' element={<About />}></Route>
              <Route path='/Contact' element={<Contact />}></Route>
             
              <Route path='/ChairmanMessage' element={<ChairmanMessage />}></Route>
              <Route path='/PrincipalMessage' element={<PrincipalMessage />}></Route>            
              <Route path='/Careers' element={<Careers />}></Route>
              <Route path='/Course' element={<Courses />}></Route>
              <Route path='/PhotoGallery' element={<PhotoGallery />}></Route>   
              <Route path='/Downloads' element={<Downloads />}></Route>     
              <Route path='/Infrastructure' element={<Infrastructure />}></Route>  
              <Route path='/Admission' element={<Admission />}></Route>   
              <Route path='/ApprovalLetters' element={<ApprovalLetters />}></Route>
          </Routes>
          <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
