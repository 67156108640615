import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
const Header = () => {
    const location = useLocation();
    const [clicked, setClickedMenu] = useState(false);
    useEffect(() => {
    AOS.init({ duration: 500, easing: "ease", once: true });
}, []);
useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "instant" });
    setClickedMenu(false);
}, [location.pathname]);
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
    const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
    // Adjust this value based on when you want the header to become sticky
    setIsSticky(true);
} else {
    setIsSticky(false);
}
};
    window.addEventListener("scroll", handleScroll);
    return () => {
    window.removeEventListener("scroll", handleScroll);
};
}, []);
    let showMenu = () => {
    setClickedMenu(!clicked);
};
    const [isVisible, setIsVisible] = useState(false);
    const handleScroll = () => {
    setIsVisible(window.pageYOffset > 100);
};
    const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth",
});
};
    useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
    window.removeEventListener("scroll", handleScroll);
};
}, []);

const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  return (
    <>
       <header class="header-style1 menu_area-light">

<div class="navbar-default border-bottom border-color-light-white">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-12">
                <div class="menu_area alt-font">
                    <nav class="navbar navbar-expand-lg navbar-light p-0">
                        <div class="navbar-header navbar-header-custom">                          
                            <Link to={'/'} class="navbar-brand"><img id="logo" src="img/logos/javitri-logo2.svg" alt="logo" /></Link>                            
                        </div>
                        <div class="navbar-toggler bg-primary" onClick={showMenu}></div>                        
                            <ul class="navbar-nav ms-auto" id="nav">
                                    <li><Link to={'/'}>Home</Link></li>
                                    <li class="has-sub">
                                        <span class="submenu-button"></span>
                                        <Link href="#!">About us</Link>
                                        <ul class="sub-menu">
                                            <li><Link to={'/About'}>About JCP</Link></li>
                                            <li><Link to={'/ChairmanMessage'}>Chairman's Message</Link></li>
                                            <li><Link to={'/PrincipalMessage'}>Principal Message</Link></li>
                                            <li><Link to={'/ApprovalLetters'}>Approval Letters</Link></li>
                                            <li><Link to={'/Downloads'}>Downloads</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to={'/Course'}>Course</Link></li>
                                    <li><Link to={'/Infrastructure'}>Infrastructure</Link></li>
                                    <li><Link to={'/PhotoGallery'}>Photo Gallery</Link></li>
                                    <li><Link to={'/Careers'}>Careers</Link></li>
                                    <li><Link to={'/Admission'}>Admission</Link></li>
                                </ul>


                                {clicked && (<ul class="navbar-nav ms-auto d-block">
                                    <li><Link to={'/'}>Home</Link></li>
                                    <li class="has-sub ff" onClick={toggleSubmenu}>
                                        <span class="submenu-button"></span>
                                        <Link href="#!">About us</Link>
                                        {isSubmenuOpen && (<ul class="sub-menu d-block">
                                            <li><Link to={'/About'}>About JCP</Link></li>
                                            <li><Link to={'/ChairmanMessage'}>Chairman's Message</Link></li>
                                            <li><Link to={'/PrincipalMessage'}>Principal Message</Link></li>
                                            <li><Link to={'/ApprovalLetters'}>Approval Letters</Link></li>
                                            <li><Link to={'/Downloads'}>Downloads</Link></li>
                                        </ul>
                                        )}
                                    </li>
                                    <li><Link to={'/Course'}>Course</Link></li>
                                    <li><Link to={'/Infrastructure'}>Infrastructure</Link></li>
                                    <li><Link to={'/PhotoGallery'}>Photo Gallery</Link></li>
                                    <li><Link to={'/Careers'}>Careers</Link></li>
                                    <li><Link to={'/Admission'}>Admission</Link></li>
                                    <li><Link to={'/Contact'}>Contact Us</Link></li>
                                </ul>)}

                       
                                <div class="attr-nav align-items-xl-center ms-xl-auto main-font">
                                    <ul>                                
                                        <li class="d-none d-xl-inline-block"><Link to={'/Contact'} class="butn md text-white"><i class="fas fa-plus-circle icon-arrow before"></i><span class="label">Contact Us</span><i class="fas fa-plus-circle icon-arrow after"></i></Link></li>
                                    </ul>
                                </div>                       
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </>
  )
}

export default Header
