import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Breadcrub from '../Common/Breadcrub';
const PhotoGallery = () => {

    const images = [
        'img/gallery/01.jpeg',
        'img/gallery/02.jpeg',
        'img/gallery/03.jpeg',
        'img/gallery/04.jpeg',
        'img/gallery/05.jpeg',
        'img/gallery/06.jpeg',
        'img/gallery/07.jpeg',
        'img/gallery/08.jpeg',
        'img/gallery/09.jpeg',
        'img/gallery/10.jpeg',
        'img/gallery/11.jpeg',
        'img/gallery/12.jpeg',
        'img/gallery/13.jpeg',
        'img/gallery/14.jpeg',
        'img/gallery/15.jpeg',
        'img/gallery/16.jpeg',
        'img/gallery/17.jpeg',
        'img/gallery/18.jpeg',
        'img/gallery/19.jpeg',
        'img/gallery/20.jpeg',
        'img/gallery/21.jpeg',
        'img/gallery/22.jpeg',
        'img/gallery/23.jpeg',
        'img/gallery/24.jpeg',
        'img/gallery/25.jpeg',
        'img/gallery/26.jpeg',
        'img/gallery/27.jpeg',
        'img/gallery/28.jpeg',
        'img/gallery/29.jpeg',
        'img/gallery/30.jpeg',
        'img/gallery/31.jpeg',
        'img/gallery/32.jpeg',
        'img/gallery/33.jpeg',
        'img/gallery/34.jpeg',
        'img/gallery/35.jpeg',
        'img/gallery/36.jpeg',
        'img/gallery/37.jpeg',
        'img/gallery/38.jpeg',
        'img/gallery/39.jpeg',
        'img/gallery/40.jpeg',
        'img/gallery/41.jpeg',
        'img/gallery/42.jpeg',
        'img/gallery/43.jpeg',
        'img/gallery/44.jpeg',
        'img/gallery/45.jpeg',
        'img/gallery/46.jpeg',
        'img/gallery/47.jpeg',
        'img/gallery/48.jpeg',
        'img/gallery/49.jpeg',
        'img/gallery/50.jpeg',
        'img/gallery/51.jpeg', 
        'img/gallery/52.jpeg',
        'img/gallery/53.jpeg',
        'img/gallery/54.jpeg',
        'img/gallery/55.jpeg',
        'img/gallery/56.jpeg',
        'img/gallery/57.jpeg',
        'img/gallery/58.jpeg',
        'img/gallery/59.jpeg',
        'img/gallery/60.jpeg',
        'img/gallery/61.jpeg',
        'img/gallery/62.jpeg',
        'img/gallery/63.jpeg',
        'img/gallery/64.jpeg',
        'img/gallery/65.jpeg',
        'img/gallery/66.jpeg',
        'img/gallery/67.jpeg',
        'img/gallery/68.jpeg',
        'img/gallery/69.jpeg',
        'img/gallery/70.jpeg',
        'img/gallery/71.jpeg',
        'img/gallery/72.jpeg',
        'img/gallery/73.jpeg',
        'img/gallery/74.jpeg',
        'img/gallery/75.jpeg',
        'img/gallery/76.jpeg',
        'img/gallery/77.jpeg',
        'img/gallery/78.jpeg',
        'img/gallery/79.jpeg',
        'img/gallery/80.jpeg',
        'img/gallery/81.jpeg',
        'img/gallery/82.jpeg',
        'img/gallery/83.jpeg',
        'img/gallery/84.jpeg',
        'img/gallery/85.jpeg',
       ];
    
      const [isOpen, setIsOpen] = useState(false);
      const [photoIndex, setPhotoIndex] = useState(0);
      const [loading, setLoading] = useState(false);
    
      const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
        setLoading(true);
      };
    
      const closeLightbox = () => {
        setIsOpen(false);
        setLoading(false);
      };
    
      const onImageLoad = () => {
        setLoading(false);
      };

      
  return (
    <>
      <Breadcrub title="Photo Gallery" />
      <section className="facility-box mt-4">
        <div className="container">          
          <div className="row mb-4">
                    <div className="col-md-12">
                            <div className="gallery text-center row">
                            {images.map((image, index) => (
                            <div key={index} className="col-md-4 mb-4">
                                <div className='gallery-block'>
                                    <img src={image} alt={`Image ${index + 1}`} onClick={() => openLightbox(index)} style={{cursor: 'pointer' }} className='gallery-thumb' />
                                </div>
                            </div>
                            ))}
                            {isOpen && (
                                <Lightbox
                                    mainSrc={images[photoIndex]}
                                    nextSrc={images[(photoIndex + 1) % images.length]}
                                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                    onCloseRequest={closeLightbox}
                                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                                    imageLoadErrorHandler={() => setLoading(false)}
                                    onImageLoad={onImageLoad}
                                />
                            )}
                            {loading && <div className="loading-indicator">Loading...</div>}

                            
                            </div>
                    </div>
                </div>	           
          </div>
        </section>
    </>
  )
}

export default PhotoGallery
