import React from 'react'
import Breadcrub from '../Common/Breadcrub'
import { Link } from 'react-router-dom'

const Downloads = () => {
  return (
    <>
      <Breadcrub title="Downloads"  />
        <section class="aboutus-style-01">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-12">
                        <div class="row g-0 event-wrapper">                           
                            <div class="col-md-12">
                                <div class="p-1-3 p-sm-1-9">
                                    <p>PCI Approval <Link to={'/img/pdf/PCI-6185_Javitri_College_of_Pharmacy.pdf'} target='blank'>Click here <i className="fas fa-file-pdf text-danger"></i></Link></p>  
                                    <p>BTE Affiliation <Link to={'/img/pdf/PCI-6185_Javitri_College_of_Pharmacy.pdf'} target='blank'>Click here <i className="fas fa-file-pdf text-danger"></i></Link></p>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Downloads
