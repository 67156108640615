import React, { useState } from 'react'
import Slider from '../Common/Slider'
import { Link } from 'react-router-dom'

const Home = () => {
    const [isOpen, setIsOpen] = useState(true);

	const openPopup = () => {
	  setIsOpen(true);
	};
  
	const closePopup = () => {
	  setIsOpen(false);
	};

  return (
    <>
        <Slider />

        <section class="p-0 overflow-visible service-block">
            <div class="container">
                <div class="row mt-n1-9">    
                    <div class="col-md-6 col-lg-4 mt-1-9">
                        <div class="card card-style3 h-100">
                        <div class="card-body px-1-9 py-2-3">
                            <div class="mb-3 d-flex align-items-center">
                                <div class="card-icon">
                                    <i class="fas fa-mail-bulk"></i>
                                </div>
                                <h4 class="ms-4 mb-0">PCI Approval</h4>
                            </div>
                            <div>
                                <p class="mb-3">Javitri College of Pharmacy is approved by Pharmacy Council of India (PCI) New Delhi.</p>
                                <Link to={'/img/pdf/PCI-6185_Javitri_College_of_Pharmacy.pdf'} class="butn-style1 secondary" target='blank'>Click Here +</Link>
                            </div>
                        </div>
                        </div>
                    </div>                
                    <div class="col-md-6 col-lg-4 mt-1-9">
                        <div class="card card-style3 h-100">
                        <div class="card-body px-1-9 py-2-3">
                            <div class="mb-3 d-flex align-items-center">
                                <div class="card-icon">
                                    <i class="fas fa-map"></i>
                                </div>
                                <h4 class="ms-4 mb-0">Admission Form</h4>
                            </div>
                            <div>
                                <p class="mb-3">Click below to Download the Admission form of Javitri College of Pharmacy Lucknow.</p>
                                <Link class="butn-style1 secondary">Click Here +</Link>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6 col-lg-4 mt-1-9">
                        <div class="card card-style3 h-100">
                        <div class="card-body px-1-9 py-2-3">
                            <div class="mb-3 d-flex align-items-center">
                                <div class="card-icon">
                                    <i class="fas fa-mail-bulk"></i>
                                </div>
                                <h4 class="ms-4 mb-0">BTE Affiliation</h4>
                            </div>
                            <div>
                                <p class="mb-3">Javitri College of Pharmacy is affiliated with Board of Technical Education Lucknow Uttar Pradesh.</p>
                                <Link to={'/img/pdf/JPCO_BTE_APPROVAL_LETTER_2023-24.pdf'} class="butn-style1 secondary" target='blank'>Click Here +</Link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>


            {/* <!-- ABOUTUS
        ================================================== --> */}
        <section class="aboutus-style-01 position-relative">
            <div class="container pt-lg-4">
                <div class="row align-items-center mt-n1-9">
                    <div class="col-md-12 col-lg-6 mt-1-9 order-2 order-lg-1">
                        <div class="position-relative">                            
                                <div className='image-hover'>
                                    <img src="img/content/pharma-04.png" alt="..." class="ps-sm-10" />
                                </div>  
                        </div>   
                    </div>
                    <div class="col-md-12 col-lg-6 mt-1-9 order-2 order-lg-1">
                        <div class="section-heading text-start mb-2">
                            <span class="sub-title">welcome!</span>
                        </div>
                        <h2 class="font-weight-800 h1 mb-1-9 text-primary">Javitri College of Pharmacy</h2>
                        <p class="about-border lead fst-italic mb-1-9">Javitri College of Pharmacy is affiliated to "Board of Techinical Education, Lucknow, Uttar Pradesh" and approved by the Pharmacy Council of India (PCI), New Delhi.</p>
                        <blockquote>
                        Education at Javitri College of Pharmacy is focused on all round development of students besides academic excellence required to excel in the modern dynamic world.
                        </blockquote>
                        <div class="dotted-seprator pt-1-9 mt-1-9"></div>
                        <div class="row">
                            <div class="col-sm-6 col-12 mb-3 mb-sm-0">
                                <div class="media">
                                    <i class="fas fa-phone display-15 text-secondary"></i>
                                    <div class="media-body align-self-center ms-3">
                                        <h4 class="mb-1 h5">Phone Number</h4>
                                        <p class="mb-0"><Link to={'tel:+91 391124124'}>+91 6391124124</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-12">
                                <div class="media">
                                    <i class="fas fa-envelope display-15 text-secondary"></i>
                                    <div class="media-body align-self-center ms-3">
                                        <h4 class="mb-1 h5">Email Address</h4>
                                        <p class="mb-0"><Link to={'mailto:info@javitricollegeofpharmacy.edu.in'} className='text-nowrap'>info@javitricollegeofpharmacy.edu.in</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shape18">
                    <img src="img/bg-01.jpg" alt="..." />
                </div>
                <div class="shape20">
                    <img src="img/bg-02.jpg" alt="..." />
                </div>
            </div>
        </section>

       

        {/* <!-- TRENDING CATEGORIES
        ================================================== --> */}
        <section class="bg-img cover-background dark-overlay" style = {{backgroundImage: 'url("img/bg/bg-03.png")'}}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="section-heading text-center">                            
                            <h2 class="h1 mb-0">Our Laboratories</h2>
                        </div>
                    </div>
                </div>
                <div class="row mt-n1-9">
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon04.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Pharmaceutics Lab</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon05.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Model Pharmacy</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon01.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Human Anatomy</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon06.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Pharmaceutical Chemistry Lab</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon02.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Pharmacology Lab</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon03.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Pharmacognosy Lab</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon07.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Pharmacy Practice Lab</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mt-1-9">
                        <div class="category-item-01">
                            <div class="category-img">
                                <img src="img/icons/icon08.png" className='icon_img' alt="" />
                            </div>
                            <div class="ms-3">
                                <h3 class="h4 mb-0 text-black">Pathology lab</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* // <!-- ONLINE INSTRUCTORS
        // ================================================== --> */}


        <section class="bg-very-light-gray">
            <div class="container">
                <div class="section-heading">                   
                    <h2 class="h1 mb-0">Gallery</h2>
                </div>
                <div class="row g-xxl-5 mt-n2-6">
                    <div class="col-md-6 col-xl-4 mt-2-6">
                        <div class="card card-style1 p-0 h-100">
                            <div class="card-img rounded-0">
                                <div class="image-hover">
                                    <img class="rounded-top gallery-thumb" src="img/gallery/01.jpeg" alt="..." />
                                </div>                               
                            </div>                            
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 mt-2-6">
                        <div class="card card-style1 p-0 h-100">
                            <div class="card-img rounded-0">
                                <div class="image-hover">
                                    <img class="rounded-top gallery-thumb" src="img/gallery/02.jpeg" alt="..." />
                                </div>                               
                            </div>                            
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 mt-2-6">
                        <div class="card card-style1 p-0 h-100">
                            <div class="card-img rounded-0">
                                <div class="image-hover">
                                    <img class="rounded-top gallery-thumb" src="img/gallery/03.jpeg" alt="..." />
                                </div>                               
                            </div>                            
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-4 mt-2-6">
                        <div class="card card-style1 p-0 h-100">
                            <div class="card-img rounded-0">
                                <div class="image-hover">
                                    <img class="rounded-top gallery-thumb" src="img/gallery/04.jpeg" alt="..." />
                                </div>                               
                            </div>                            
                        </div>
                    </div>

                   

                    <div class="col-md-6 col-xl-4 mt-2-6">
                        <div class="card card-style1 p-0 h-100">
                            <div class="card-img rounded-0">
                                <div class="image-hover">
                                    <img class="rounded-top gallery-thumb" src="img/gallery/05.jpeg" alt="..." />
                                </div>                               
                            </div>                            
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 mt-2-6">
                        <div class="card card-style1 p-0 h-100">
                            <div class="card-img rounded-0">
                                <div class="image-hover">
                                    <img class="rounded-top gallery-thumb" src="img/gallery/06.jpeg" alt="..." />
                                </div>                               
                            </div>                            
                        </div>
                    </div>
                    
                </div>

                <div class="row g-xxl-5 mt-n2-6">
                    <div class="col-md-12 col-xl-12 mt-2-6">
                       <div className='text-center mt-5'>
                            <Link to={'/PhotoGallery'} class="butn md text-white">
                                <i class="fas fa-plus-circle icon-arrow before"></i><span class="label">View More</span>
                            </Link>
                       </div>
                    </div>
                </div>
            </div>
        </section>

       

        <section class="p-0">
        <iframe  width="100%" height="500" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.6415087414016!2d81.000648575214!3d26.627935376818154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef9d94aa71a9%3A0x427ae97ef086a9f0!2sJavitri%20Insitute%20of%20Medical%20Sciences%20and%20Pharmacy%20Division!5e0!3m2!1sen!2sin!4v1716744904182!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </section>

        {/* {isOpen && (<div class="modal d-block z-10 bd-example-modal-lg" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header p-0">					
					<button type="button" onClick={closePopup} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body p-0">
					 <img src='img/content/model.jpeg' alt='' />
				</div>
			
			</div>
		</div>
	</div>  )} */}
    </>
  )
}

export default Home
