import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
        <footer class="bg-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-4 mb-2-5 mb-lg-0">
                        <a href="!#" class="footer-logo">
                            <img src="img/logos/javitri-logo.svg" class="mb-4 footer_logo" alt="Footer Logo" />
                        </a>
                        <ul class="social-icon-style1 gap-2 d-flex">
                            <li><Link class="fab fa-facebook-f"></Link></li>
                            <li><Link><i class="fab fa-twitter"></i></Link></li>
                            <li><Link><i class="fab fa-youtube"></i></Link></li>
                            <li><Link><i class="fab fa-linkedin-in"></i></Link></li>
                        </ul>                      
                    </div>
                    <div class="col-md-6 col-lg-4 mb-2-5 mb-lg-0">
                        <div class="ps-md-1-6 ps-lg-1-9">
                            <ul class="footer-list">
                                <li><Link to={'/About'}>About</Link></li>
                                <li><Link to={'/ChairmanMessage'}>Chairman's Message</Link></li>
                                <li><Link to={'/PrincipalMessage'}>Principal Message</Link></li>
                                <li><Link to={'/PhotoGallery'}>Photo Gallery</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-2-5 mb-md-0">
                        <div class="ps-lg-1-9 ps-xl-2-5">
                             <ul class="footer-list">
                                <li><Link to={'/'}>Our Facilities</Link></li>
                                <li><Link to={'/Courses'}>Course</Link></li>
                                <li><Link to={'/Admission'}>Admission</Link></li>
                                <li><Link to={'/Contact'}>Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div class="footer-bar text-center">
                    <p class="mb-0 text-white font-weight-500">&copy; <span class="current-year"></span> Javitri College of Pharmacy 2024. All Rights Reserved. Powered by <a href="https://adventusindia.com/" class="text-secondary" target='blank'>Adventus</a></p>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer
