import React from 'react'
import Breadcrub from '../Common/Breadcrub'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <>
      <Breadcrub title="Contact Us" />
        <section class="bg-very-light-gray py-md-8">
            <div class="container">
                <div class="row mt-n2-9">
                    <div class="col-lg-4 mt-2-9">
                        <div class="contact-wrapper bg-light rounded position-relative h-100 px-4">
                            <div class="mb-4">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <div>                              
                                <ul class="list-unstyled p-0 m-0">
                                <li><Link to={'mailto:info@javitricollegeofpharmacy.edu.in'}>info@javitricollegeofpharmacy.edu.in</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-2-9">
                        <div class="contact-wrapper bg-light rounded position-relative h-100 px-4">
                            <div class="mb-4">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <div>                               
                                <ul class="list-unstyled p-0 m-0">
                                <li>Kankaha, Raibareli Road, Lucknow 227302</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-2-9">
                        <div class="contact-wrapper bg-light rounded position-relative h-100 px-4">
                            <div class="mb-4">
                                <i class="fas fa-phone"></i>
                            </div>
                            <div>                              
                                <ul class="list-unstyled p-0 m-0">
                                <li><Link to={'tel:+919838778776'}>+91 9838778776</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                <div class="row align-items-end">                   
                    <div class="col-lg-12">
                        <div class="faq-form">
                            <h2 class="mb-4 text-primary">Get In Touch</h2>
                            <form class="contact quform">
                                <div class="quform-elements">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="quform-element form-group">
                                                <label for="name">Your Name <span class="quform-required">*</span></label>
                                                <div class="quform-input">
                                                    <input class="form-control" id="name" type="text" name="name" placeholder="Your name here" />
                                                </div>
                                            </div>
                                        </div>                                      
                                        <div class="col-md-6">
                                            <div class="quform-element form-group">
                                                <label for="email">Your Email <span class="quform-required">*</span></label>
                                                <div class="quform-input">
                                                    <input class="form-control" id="email" type="text" name="email" placeholder="Your email here" />
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-6">
                                            <div class="quform-element form-group">
                                                <label for="subject">Your Subject <span class="quform-required">*</span></label>
                                                <div class="quform-input">
                                                    <input class="form-control" id="subject" type="text" name="subject" placeholder="Your subject here" />
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-6">
                                            <div class="quform-element form-group">
                                                <label for="phone">Contact Number</label>
                                                <div class="quform-input">
                                                    <input class="form-control" id="phone" type="text" name="phone" placeholder="Your phone here" />
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-md-12">
                                            <div class="quform-element form-group">
                                                <label for="message">Message <span class="quform-required">*</span></label>
                                                <div class="quform-input">
                                                    <textarea class="form-control" id="message" name="message" rows="3" placeholder="Tell us a few words"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="quform-submit-inner">
                                                <button class="butn secondary" type="submit"><i class="far fa-paper-plane icon-arrow before"></i><span class="label">Send Message</span><i class="far fa-paper-plane icon-arrow after"></i></button>
                                            </div>
                                            <div class="quform-loading-wrap text-start"><span class="quform-loading"></span></div>
                                        </div>                                       
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

       
        <section class="p-0">
        <iframe  width="100%" height="500" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.6415087414016!2d81.000648575214!3d26.627935376818154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef9d94aa71a9%3A0x427ae97ef086a9f0!2sJavitri%20Insitute%20of%20Medical%20Sciences%20and%20Pharmacy%20Division!5e0!3m2!1sen!2sin!4v1716744904182!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         
        </section>
    </>
  )
}

export default Contact
