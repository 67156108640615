import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrub = (props) => {
  return (
    <>
      <section class="page-title-section bg-img cover-background top-position1 left-overlay-dark" data-overlay-dark="9"  style = {{backgroundImage: 'url("img/bg/bg.png")'}}>
            <div class="container">
                <div class="row text-center">
                    <div class="col-md-12">
                        <h1>{props.title}</h1>
                    </div>
                    <div class="col-md-12">
                        <ul>
                            <li><Link to={'/'}>Home</Link></li>
                            <li>{props.title}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Breadcrub
