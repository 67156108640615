import React from 'react'
import Breadcrub from '../Common/Breadcrub'

const Course = () => {
  return (
    <>
       <Breadcrub title="Course" />
        <section>
            <div class="container">
                <div class="row mb-1-9 mb-xl-2-9">
                    <div class="col-lg-5 mb-1-9 mb-md-2-5 mb-lg-0">
                        <div class="instructor-img-wrapper mb-50 md-mb-0">
                            <div class="mb-1-6 mb-xl-1-9">
                                <img class="border-radius-5" src="img/content/d-pharm-02.png" alt="..." />
                            </div>
                            <div class="text-center">
                                <h3 class="font-weight-800 display-26 display-md-25 display-xl-24 text-primary">D. Pharm (Diploma in Pharmacy)</h3>
                                <p class="alt-font text-secondary font-weight-700 mb-2">2 Years full-time diploma course in Pharmacy.</p>    
                                <p class="alt-font text-secondary font-weight-700 mb-2">Intake: 60 (as per government norms.)</p>                         
                            </div>                       
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="ps-lg-1-9 ps-xl-6">
                            <div class="row mb-1-9 mb-xl-2-9 item-center">
                                <div class="col-lg-12">
                                    <h4 class="teacher-name mb-4">D. Pharm (Diploma in Pharmacy)</h4>                                   
                                    <ul>
                                        <li>Completion of 10+2 (or equivalent) with a focus on science subjects (Physics, Chemistry, Biology/Mathematics).</li>
                                        <li><b>First Year:</b> Subjects typically include Pharmaceutical Chemistry, Pharmacognosy, Biochemistry, Human Anatomy and Physiology, Health Education and Community Pharmacy.</li>
                                        <li><b>Second Year:</b> Subjects often include Pharmacology and Toxicology, Pharmaceutical Jurisprudence, Drug Store and Business Management, Hospital and Clinical Pharmacy, Pharmaceutical Chemistry (Organic and Inorganic), and Pharmaceutics.</li>
                                    </ul>
                                </div>
                            </div>                                      
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
  )
}

export default Course
