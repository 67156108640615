import React from 'react'
import Breadcrub from '../Common/Breadcrub'
import { Link } from 'react-router-dom'

const Careers = () => {
  return (
    <>
      <Breadcrub title="Careers"  />
        <section class="aboutus-style-01">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-12">
                        <div class="row g-0 event-wrapper">                           
                            <div class="col-md-12">
                                <div class="p-1-3 p-sm-1-9">
                                    <p>We are always on a lookout for young (at heart), enthusiastic, and spunky individuals to lend their best efforts, time and personality to Javitri College of Pharmacy. If you feel we ought to check you out, we most certainly will.</p>
                                    <p>If you are interested in exploring opportunities with Javitri College, write us at <Link to={'mailto:info@javitricollegeofpharmacy.edu.in'}>info@javitricollegeofpharmacy.edu.in</Link></p>                             
                                    <p>Please mention name of the position you wish to apply in Subject Line.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Careers
