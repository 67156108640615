import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { } from 'react-router-dom';
const Slider = () => {
    const options = {
        items: 1, // Set the number of items to display
        autoplay: true,
        autoplayTimeout: 3000, // Time between slides in milliseconds
        loop: true, // Enable loop
        nav: false, // Enable navigation arrows
    };
   
    let arr=[ 
        {
            img:'img/slider/01.png',   
            head: 'Javitri College of Pharmacy', 
            des:'Affiliated With Board of Technical Eductaion Uttar Pradesh & Approved By PCI'  
        },
            {
            img:'img/slider/01.png',      
            head: 'Javitri College of Pharmacy', 
            des:'Affiliated With Board of Technical Eductaion Uttar Pradesh & Approved By PCI'  
        },


        {
            img:'img/slider/04.png',        
            head: 'Javitri College of Pharmacy', 
            des:'Affiliated With Board of Technical Eductaion Uttar Pradesh & Approved By PCI'   
        },    

        {
            img:'img/slider/05.png',        
            head: 'Javitri College of Pharmacy', 
            des:'Affiliated With Board of Technical Eductaion Uttar Pradesh & Approved By PCI'   
        },    
    ]
    
  return (
    <section class="top-position1 p-0">
            <div class="container-fluid px-0">
            <OwlCarousel className='slider-fade1 owl-carousel owl-theme w-100' {...options}>
                {arr.map((item)=>{
                    return  <div class="item bg-img cover-background pt-6 pb-10 pt-sm-6 pb-sm-14 py-md-16 py-lg-20 py-xxl-24 left-overlay-dark" data-overlay-dark="8"  style={{ backgroundImage: 'url(' + item.img + ')'}}>
                        <div class="container pt-6 pt-md-0">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-12 col-xl-12 mb-1-9 mb-lg-0 py-6 position-relative">
                                    <span class="h5 text-secondary">{item.des}</span>
                                    <h1 class="display-1 font-weight-800 mb-2-6 title text-white">{item.head}</h1>
                                    {/* <Link to={'/Contact'} class="butn my-1 mx-1"><i class="fas fa-plus-circle icon-arrow before"></i><span class="label">Contact Us</span><i class="fas fa-plus-circle icon-arrow after"></i></Link>
                                    <Link to={'/Course'} class="butn white my-1"><i class="fas fa-plus-circle icon-arrow before"></i><span class="label">Our Courses</span><i class="fas fa-plus-circle icon-arrow after"></i></Link> */}
                                </div>
                            </div>
                        </div>

                    </div>
                
                })} 
             </OwlCarousel>  
            </div>
            <div class="triangle-shape top-15 right-10 z-index-9 d-none d-md-block"></div>
            <div class="square-shape top-25 left-5 z-index-9 d-none d-xl-block"></div>
            <div class="shape-five z-index-9 right-10 bottom-15"></div>
        </section>
  )
}

export default Slider
