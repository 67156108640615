import React from 'react'
import Breadcrub from '../Common/Breadcrub'

const ChairmanMessage = () => {
  return (
    <>
      <Breadcrub title="Chairman's Message" />
      <section class="mt-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <p>Dear Prospective Students,</p>
                    <p>To allow an assailable opportunity to my young coevals and furnish them as skilled world class professionals for the pharmaceutical world with deep thorough knowledge, I'm hereby introducing Javitri Institute of Medical Sciences & Pharmacy Division'.</p>
                    <p>Our focus is to fulfil this commitment by bringing in the philosophy that industry and education must co-exist while knowledge and experience will continuously and dynamically keep the students performing at the peak level, present and in the future. The institute has adopted the best features of the education to equip you with attributes to succeed in fiercely competitive business world with grace and without compromising with universal values.</p>
                    <p>I'm pleased to note the progress that we are proceeding in the direction of establishing this institute one of the biggest pharmaceutical education and research hub in India.</p>
                    <p>I feel pleasure to join hands with you in relentless pursuit to world-class education with excellence.</p>
                    <p>
                    <h4>Dr. I. Tyagi</h4>
                    </p>
                    <p>Chairman</p>
                </div>
                <div class="col-lg-4">
                    <div class="xs-text-center mt-5"><img src="img/content/chairman.jpg" alt="" class="border-radius-6 w-100" /></div>
                </div>
            </div>
        </div>
        </section>
    </>
  )
}

export default ChairmanMessage
